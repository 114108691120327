.app-title {
  background-color: #232f3e;
  margin-top: 0px;
  padding: 30px;
  color: #ffffff;
  background-image: url("//d1.awsstatic.com/management-console/ImageHeader_Sign-out-Console_Hero2_Blue.2ea44d1ab1a2270a80c1f6dfc2b41f6b5d69b7b9.jpg");
}

.app-title-main-text {
  font-size: 4.4rem;
  font-size: 40px;
  line-height: 1.2;
  font-weight: 600;
  margin-bottom: 5px;
}

/* .app-title-sub-text {
}

.app-login-btn-group {
} */

.app-intro-area {
  padding: 30px;
}

.app-intro-area-text {
  font-size: 1.8rem;
  line-height: 1.2;
  font-weight: 200;
}
