#custom-main-layout > div > div > div > div {
  top: 50px !important;
}

#custom-main-layout > div > div > main {
  margin-top: 50px;
}

#custom-main-layout > div > div > main > div > div {
  padding: 0 !important;
}

/* main content except navbar and header  */
.main-content {
  min-height: calc(100vh - 50px - 50px);
  padding: 2rem 3rem;
  box-sizing: border-box;
}
